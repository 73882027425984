export default {
  1: `
    <div>
      <p>
        The Super Admin role is reserved for developers & support staff. No clients should be given the role of Super
        Admin. Super Admins can see & do anything in EZActivityTrips.
      </p>
    </div>
  `,
  2: `
    <div>
      <p>Transportation Admin users have the highest level of access. Individuals in this role have full visibility into the system including trips and vehicles from all locations as well as invoicing.
      </p>
    </div>
  `,
  3: `
    <div>
      <p>Limited Admin has the same authority as a transportation admin, but may not have access to the Settings and Setup Tables.
      </p>
    </div>
  `,
  4: `
    <div>
      <p>Finance can post the final payments from the invoices who will be responsible for marking invoices as “paid”
      </p>
    </div>
  `,
  5: `
    <div>
      <p>The Driver role is for the drivers of the trips created in EZActivityTrips.</p>
    </div>
  `,
  6: `
    <div>
      <p>
        The Driver Table Only role allows users to manage the Drivers Table, but not the other tables in the Setup
        Tables tab.
      </p>
    </div>
  `,
  7: `
    <div>
      <p>The Read Only role allows users to see most things, but not modify them.</p>
    </div>
  `,
  8: `
    <div>
      <p>Overnight/Out-of-State is typically the Board Secretary who would need access to run the list of overnight and/or out-of-state trips for the board report each month. This setting only gives them access to view the board report and Overnight/Out-of-State trips but does not give them authority to approve these trips. If this person is also the person responsible for approving Overnight/Out-of-State trips, they should also be listed in the Central Office Approval level at the appropriate locations.
      </p>
    </div>
  `,
  9: `
    <div>
      <p>Approvers are multiple levels of persons that can be designated in the EZActivityTrips. A trip will have varying levels of approvers based on selections in the trip, the requesting location, certain attributes of the trip (for example overnight or out of county) and funding sources. If an approver is listed on a trip, they will receive an email (see NotApp email) as soon as the previous approver has approved the trip and the trip is ready for their approval. Once all approvers have approved a trip, the status of the trip will be “Approved” and the trip requester will be notified via email.
      </p>
    </div>
  `,
  10: `
    <div>
      <p>The Site Administrator role gives full visibility to the trip requests, vehicles and drivers assigned and invoicing for their assigned site(s). Site Administrators are not in the approval flow of trips and do not receive notifications when a trip needs to be approved but they do have the authority to approve at level 1 (as a backup in the event the approver is out). They also have access to exports/reports for their site location. Site Administrators are often the principal or assistant principal. Many times this same person is listed as an approver. Site Admins have greater access than Location Approvers so if you want the location approvers to have full visibility of trips and invoices at their site you can include them in both areas. Site administrators can optionally receive an email when trips are fully approved at their sites.
      </p>
    </div>
  `,
  11: `
    <div>
      <p>The Site Admin - Read Only role allows this user the same visibility as a Site Admin, but they cannot make changes or approvals.</p>
    </div>
  `,
  12: `
    <div>
      <p>The Site Admin - Reports Only role allows this user to only access reports for their designated location(s).</p>
    </div>
  `,
  13: `
    <div>
      <p>The School Finance is typically the bookkeeper for this site location. They have access to the Assignments Tab to process invoices. They also have access to Third Party Payment and will be notified when a teacher requests a check payable to say the venue where they are going before the day of the trip. There is also a view they may access to look at all the third-party payment requests. They also have access to the export/reports for their site location and they have visibility of all trip requests from their location. There is an option to give them authority to make changes to the invoice in order to change the driver. This person may also enter mileage and driver hours for trips at their location.
      </p>
    </div>
  `,
  14: `
    <div>
      <p>The Vehicle Owner is the person responsible for assigning vehicles for a location. They may be notified via an email or they may also log into the system and access requests to assign buses and drivers. This can be one person who assigns vehicles and drivers for the entire district or it can be individual site-based vehicle owners. If transportation assigns all vehicles, the same person would be entered at each Location. This individual may optionally be given the authority to manage invoices. This is typically done in larger districts where zone supervisors are responsible for assigning vehicles and managing invoices for their zone. This means they may edit and release the invoice for their assigned location. This individual is also typically the person who prints trip tickets for drivers.
      </p>
    </div>
  `,
  15: `
    <div>
      <p>The Special Needs Owner gives districts the option to have a different vehicle owner to assign special needs trips. They may be notified via an email or they may also log into the system when they have trips identified as Special Needs trips. In order to use a special needs owner, the trip type of Special Needs should be active and set to show for Travel with Students. This option must be set up in the Setup Table / Trip Type. A Special Needs Vehicle Owner will also need to be added to the setup table in each site location if you use a special needs owner.
      </p>
    </div>
  `,
  16: `
    <div>
      <p>The School Nurse for each site location is used to allow school nurses to be notified when students with health concerns are traveling. They have the option to be notified for every trip occurring or for only trips where it has been indicated on the trip request that there are students traveling with health concerns.
      </p>
    </div>
  `,
  17: `
    <div>
      <p>The Child Nutrition is typically the Child Nutrition Manager or Cafeteria Manager for that site. Many districts will add their Child Nutrition Central Office Director in as the second Child Nutrition contact if they want to receive the out of school for lunch period notifications as well. The Child Nutrition contacts will be notified when students are traveling and will be away during lunch. They may also be notified if packed lunches have been requested.
      </p>
    </div>
  `,
  18: `
    <div>
      <p>The Reports Only role allows users to see all reports for a district.
      </p>
    </div>
  `,
  19: `
    <div>
      <p>The Requester role allows users to submit trip requests and view ones they’ve submitted.</p>
    </div>
  `,
  20: `
    <div>
      <p>This is a global role that needs to view trips involving students away for lunch (trip request option). These are the only trips they should see, in addition to any they request themselves. </p>
  `,
  23: `
    <div>
      <p>The Funding Manager is similar to a School Finance, however, they're permissions are based on the funding sources and payment status intead of a location..</p>
    </div>
  `,
};
